import { _delete, _get, _post, _put } from 'libs/api/base';
import { AccessLevelsRow } from 'models/access_level';

export function getResourceAccessLevel(
  resource_id: string,
  resource_type: string
) {
  return _post<{ access_level: number }>(`/access_level`, {
    resource_id,
    resource_type
  });
}

export function getResourceAccessLevels(
  resource_ids: string[],
  resource_type: string
) {
  return _post<AccessLevelsRow[]>(`/access_levels`, {
    resource_ids,
    resource_type
  });
}
