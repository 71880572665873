import * as React from 'react';
import { TableCell } from '@material-ui/core';
import { Folder } from '@material-ui/icons';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { ListItem, NotificationItem } from 'models/dependency';
import { AccessLevel, AccessLevelString } from 'libs/accessLevel';
import {
  Checkbox,
  LinkCell,
  NotificationDstCell,
  OpenMenuType,
  OpenShareDialogType,
  ShareDialogCell,
  TimestampCell
} from 'ui/listViewTableCell';

const cellStyle = makeStyles({
  checkCell: {
    borderBottom: 0
  }
});

export const NotificationRow: React.FC<{
  notification: NotificationItem;
  onClickItem?: (item: ListItem) => void;
  onOpenShareDialog?: OpenShareDialogType;
  onOpenMenu: OpenMenuType;
  checked: boolean;
  onCheckChanged: (checked: boolean, item: ListItem) => void;
  folderOnly?: boolean;
  disableCheck?: boolean;
  basePath: string;
  clearForm: (folderId: string, isFolder: boolean, path?: string) => void;
  isRowAccessControl?: boolean;
}> = ({
  notification,
  onClickItem,
  onOpenShareDialog,
  onOpenMenu,
  onCheckChanged,
  checked,
  folderOnly,
  disableCheck,
  basePath,
  clearForm,
  isRowAccessControl
}) => {
  const classes = cellStyle();
  const onClick = onClickItem
    ? React.useCallback(() => {
        clearForm(notification.uuid, notification.is_folder);
        onClickItem(notification);
      }, [clearForm, onClickItem, notification])
    : React.useCallback(() => {
        clearForm(
          notification.uuid,
          notification.is_folder,
          `${basePath}/f/${notification.uuid}`
        );
      }, [basePath, clearForm, notification]);

  if (notification.is_folder) {
    return (
      <>
        {!disableCheck && (
          <TableCell padding="checkbox" className={classes.checkCell}>
            <Checkbox
              checked={checked}
              onChange={(_, checked) => onCheckChanged(checked, notification)}
            />
          </TableCell>
        )}
        <LinkCell
          href={`${basePath}/f/${notification.uuid}`}
          name={notification.name}
          icon={<Folder />}
          onClick={onClick}
        />
        <TableCell>{AccessLevelString(notification.access_level)}</TableCell>
        <TableCell>-</TableCell>
        <TimestampCell time={notification.created_at} />
        <TimestampCell time={notification.updated_at} />
        <ShareDialogCell
          name={notification.name}
          uuid={notification.uuid}
          accessLevel={notification.access_level}
          onOpenShareDialog={onOpenShareDialog}
          onOpenMenu={onOpenMenu}
        />
      </>
    );
  } else {
    return (
      <>
        {!disableCheck && (
          <TableCell padding="checkbox" className={classes.checkCell}>
            <Checkbox
              checked={checked}
              onChange={(_, checked) => onCheckChanged(checked, notification)}
            />
          </TableCell>
        )}
        {folderOnly ? (
          <TableCell>{notification.name}</TableCell>
        ) : isRowAccessControl &&
          notification.access_level < AccessLevel.Viewer ? (
          <TableCell style={{ color: '#f44336' }}>権限がありません</TableCell>
        ) : (
          <LinkCell
            href={`/notifications/${notification.uuid}`}
            name={notification.name}
            onClick={onClickItem ? onClick : undefined}
          />
        )}
        <TableCell>{AccessLevelString(notification.access_level)}</TableCell>
        <NotificationDstCell type={notification.type} />
        <TimestampCell time={notification.created_at} />
        <TimestampCell time={notification.updated_at} />
        <ShareDialogCell
          name={notification.name}
          uuid={notification.uuid}
          accessLevel={notification.access_level}
          onOpenShareDialog={onOpenShareDialog}
          onOpenMenu={onOpenMenu}
        />
      </>
    );
  }
};
