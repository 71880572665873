import { _delete, _get, _post, _put } from 'libs/api/base';
import { ConnectionSchema } from 'models/connection';
import { QueryParams } from 'ui/listViewBase';

export function getConnection(id: string) {
  return _get(`/connections/${id}`);
}

export async function getConnections(type: string, queryParams?: QueryParams) {
  return _get<ConnectionSchema[]>(`/connections`, {
    params: { type, ...queryParams }
  });
}

export function addConnection(conn: ConnectionSchema) {
  return _post<{ id: string; raw_id: number }>('/connections', conn);
}

export function updateConnection(conn: ConnectionSchema) {
  return _post(`/connections/${conn.uuid}`, conn);
}

export function updateConnectionName(id: string, name: string) {
  return _put(`/connections/${id}/name`, { name });
}

export function deleteConnections(id: string) {
  return _delete(`/connections/${id}`);
}

export function healthConnection(connection: ConnectionSchema) {
  return _post('/connections/health', connection);
}

export async function getAdminConnections(queryParams?: QueryParams) {
  return _get(`/admin/connections`, { params: { ...queryParams } });
}

export async function deleteConnectionMember(
  cid: number,
  id: string,
  is_group: boolean
) {
  return _delete(`/connections/${cid}/members`, { id, is_group });
}

export function sendZohoInitiationRequest(
  client_id: string,
  account_server: string
) {
  return _get<{
    user_code: string;
    device_code: string;
    verification_url: string;
    error: string;
  }>('/zohoInitiationRequest', { params: { client_id, account_server } });
}

export function getZohoAccessToken(
  client_id: string,
  client_secret: string,
  device_code: string,
  account_server: string
) {
  return _get<{
    access_token: string;
    refresh_token: string;
    api_domain: string;
    error: string;
  }>('/zohoAccessTokenRequest', {
    params: { client_id, client_secret, device_code, account_server }
  });
}
