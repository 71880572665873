import React, { createContext, useContext } from 'react';
import Tooltip, { TooltipProps } from '@material-ui/core/Tooltip';

export const AccessControlContext = createContext<{ viewerDisabled: boolean }>({
  viewerDisabled: false
});

export const AccessControlProvider = ({ children, viewerDisabled }) => {
  return (
    <AccessControlContext.Provider value={{ viewerDisabled }}>
      {children}
    </AccessControlContext.Provider>
  );
};

export const useAccessControlContext = () => {
  return useContext(AccessControlContext);
};

export const viewerDisabledTooltipTitle = '閲覧専用のため、操作できません';

export const ViewerDisabledTooltip: React.FC<
  Omit<TooltipProps, 'title'> & { open?: boolean }
> = ({ children, open, ...props }) => {
  const title = open ? viewerDisabledTooltipTitle : '';

  return (
    <Tooltip {...props} title={title}>
      <span>{children}</span>
    </Tooltip>
  );
};
