import ActionTypes from 'actions/actionTypes';
import { AccessLevel } from 'libs/accessLevel';
import { blue, green, grey, red } from '@material-ui/core/colors';
import { Port } from 'models/graph';
import { DatasourceItem } from 'models/dependency';
import { Variable } from 'models/project';
import { ConnectionSchema } from 'models/connection';
import { DatasourceStatus } from 'models/datasource';

export function createLink(type: string, id: number | string): string {
  switch (type) {
    case 'csv_v2':
    case 'spark_csv_v2':
    case 's3':
    case 'spark_s3':
    case 'gss':
    case 'twitter':
    case 'youtube':
    case 'sftp':
      return `/datasources_v2/${id}`;
    default:
      return `/datasources/${id}`;
  }
}

export function DatasourceStatusText(s: DatasourceStatus): string {
  switch (s) {
    case DatasourceStatus.Completed:
      return '成功';
    case DatasourceStatus.Running:
      return '実行中';
    case DatasourceStatus.Failed:
      return 'エラー';
    default:
      return '不明';
  }
}

export function DatasourceStatusColor(s: DatasourceStatus): string {
  switch (s) {
    case DatasourceStatus.Running:
      return blue['A700'];
    case DatasourceStatus.Completed:
      return green['A700'];
    case DatasourceStatus.Failed:
      return red['A700'];
    default:
      return grey[900];
  }
}

export interface Datasource extends DatasourceItem {
  organization_id: number;
  connection?: ConnectionSchema;
  name: string;
  params: any;
  creator_id: string;
  node_id: string;
  connectionId?: number;
  connectionUuid?: string;
  access_level: AccessLevel;
  error: string;
  created_at: string;
  updated_at: string;
  project_created_at: string; // 分析プロジェクトかSQLビルダープロジェクトの作成日時
  port?: Port;
  variables: Variable[];
  uuid: string;
  project_id: string; // 分析プロジェクトUUIDの場合と、SQLビルダープロジェクトのIDをエンコードしたものの場合がある
  project_name: string; // 分析プロジェクトかSQLビルダープロジェクトの名前
  creating: boolean;
  cache_key: string;
  disabled_nehan_internal?: boolean;
  scheduling_locked: boolean;
  has_permission: boolean; // ノード上で選択されたデータソースに権限があるかどうか
}

export interface Table {
  database: string;
  schema?: string;
  name: string;
  type: string;
}

export interface DatasourceState {
  testData: Datasource[];
  userData: Datasource[];
  nehanStorage: Datasource[];
  builderWayPoint: Datasource[];
  loading: boolean;
  loaded: boolean;
}

export default function datasources(
  state: DatasourceState = {
    testData: [],
    userData: [],
    nehanStorage: [],
    builderWayPoint: [],
    loading: false,
    loaded: false
  },
  action: any
): DatasourceState {
  switch (action.type) {
    case ActionTypes.LoadingDatasources:
      return { ...state, loading: true };

    case ActionTypes.LoadDatasources:
      return {
        loading: false,
        loaded: true,
        testData: action.payload.test_data || [],
        userData: action.payload.user_data || [],
        nehanStorage: action.payload.nehan_storage || [],
        builderWayPoint: action.payload.builder_waypoint || []
      };

    default:
      return state;
  }
}
